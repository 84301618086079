<template>
  <dx-validation-group>
    <div class="login-header">
      <img :src="logoimage" alt="" />
      <div class="title1">
        <!-- <span style="display: inline-block"><img :src="icontruck" /></span>&nbsp; -->
        {{ title }}
      </div>
      <!-- <div class="version">Versi {{ version }}</div> -->
      <!-- <div>Sign In to your account</div> -->
    </div>
    <div class="form">
      <DxTextBox
        id="progress-text"
        placeholder="Masukkan No Invoice disini"
        @value-changed="valueChanged"
      />
      <DxButton
        id="progress-button"
        :text="buttonText"
        :width="200"
        :on-click="onButtonClick"
      />
      <!-- <div class="progress-info">Time left {{ time(seconds) }}</div> -->

      <DxProgressBar
        id="progress-bar-status"
        :class="{ complete: seconds == 0 }"
        :min="0"
        :max="maxValue"
        :status-format="statusFormat"
        :value="progressValue"
        width="90%"
      />
      <div class="desc">
        <!-- <span style="display: inline-block"><img :src="icontruck" /></span>&nbsp; -->
        {{ desc }}
      </div>
      <DxPopup
        :visible="popupCheckDescription"
        :drag-enabled="false"
        :close-on-outside-click="closeOnOutsideClick"
        :show-title="true"
        :width="500"
        :height="350"
        :showCloseButton="false"
        title="Status Pembayaran Invoice"
      >
        <DxForm
          :form-data="getXenditStatus ? getXenditStatus : dataNull"
          :read-only="true"
          :col-count="2"
        >
          <DxGroupItem>
            <DxSimpleItem data-field="id" :label="{ text: 'Xendit Id' }" />
            <DxSimpleItem data-field="external_id" :label="{ text: 'No WO' }" />
            <DxSimpleItem data-field="payer_email" :label="{ text: 'Email' }" />
          </DxGroupItem>
          <DxGroupItem>
            <DxSimpleItem
              data-field="payment_method"
              :label="{ text: 'Metode Pembayaran' }"
            />
            <DxSimpleItem
              data-field="paid_amount"
              :label="{ text: 'Jumlah Pembayaran' }"
            />
            <DxSimpleItem
              data-field="status"
              :label="{ text: 'Status Pembayaran' }"
            />
          </DxGroupItem>
        </DxForm>
      </DxPopup>
    </div>
  </dx-validation-group>
</template>
<script>
import DxTextBox from "devextreme-vue/text-box";
import { DxButton } from "devextreme-vue/button";
import DxValidationGroup from "devextreme-vue/validation-group";
import { DxProgressBar } from "devextreme-vue/progress-bar";
import {
  DxForm,
  DxSimpleItem,
  DxGroupItem,
  // DxRequiredRule,
} from "devextreme-vue/form";
import { DxPopup } from "devextreme-vue/popup";
import { confirm } from "devextreme/ui/dialog";
import axios from "axios";
axios.defaults.baseURL = process.env.VUE_APP_BASE_API_URL;
const maxValue = 10;

export default {
  components: {
    // formRef,
    DxButton,
    DxProgressBar,
    DxTextBox,
    DxForm,
    DxGroupItem,
    DxSimpleItem,
    DxPopup,
    DxValidationGroup,
  },
  data() {
    return {
      logoimage: require("@/assets/images/mitsubishi.png"),
      // icontruck: require("@/assets/images/clipboard.svg"),
      title: this.$appInfo.title,
      desc: this.$appInfo.desc,
      version: this.$appVersion.version,
      maxValue,
      seconds: maxValue,
      buttonText: "Cek Invoice",
      inProgress: false,
      invoiceId: "",
      xenditId: "",
      xenditPaymentStatus: "",
      progresStatus: "",
      popupCheckDescription: false,
      getXenditStatus: [],
      dataNull: [
        {
          id: "N/A",
          external_id: "N/A",
          payer_email: "N/A",
          payment_method: "N/A",
          paid_amount: "N/A",
          status: "N/A",
        },
      ],
      // statusFormat,
    };
  },
  created() {
    // this.userId = window.localStorage.getItem("usrid");
    // this.currentUUid = this.uuid;
    // axios
    //   .get(process.env.VUE_APP_BASE_API_URL + "source_types")
    //   .then((response) => {
    //     this.GetSourceType = response.data.data;
    //   });
    // axios
    //   .get(
    //     process.env.VUE_APP_BASE_API_URL + "stocks/checkinvoiceid/A201206424"
    //   )
    //   .then((response) => {
    //     console.log(JSON.stringify(response.data.data));
    //     // this.GetWarehouse = response.data.data;
    //   });
  },
  computed: {
    progressValue() {
      return maxValue - this.seconds;
    },
  },
  methods: {
    closeOnOutsideClick() {
      this.popupCheckDescription = false;
      // return false;
    },
    statusFormat(value) {
      // if ((value > 0) & (value < 0.2)) {
      //   // this.getXenditIdByInvoiceId(invoiceId);
      //   return `Loading: ${value * 100}%, check xendit id in graylite...`;
      // } else if ((value > 0) & (value < 0.4)) {
      //   return `Loading: ${value * 100}%, xendit id not found... `;
      // } else if ((value > 0) & (value < 0.6)) {
      //   return `Loading: ${value * 100}%, register invoice to xendit... `;
      // } else if ((value > 0) & (value < 1)) {
      //   return `Loading: ${value * 100}%, get payment status from xendit...`;
      // } else {
      //   return ``;
      // }
      // var vthis = this;
      return `Loading: ${value * 100}%, ` + this.progresStatus;
    },
    valueChanged(data) {
      this.invoiceId = data.value;
    },
    time(value) {
      return `00:00:${`0${value}`.slice(-2)}`;
    },

    async getXenditIdByInvoiceId(invoice_id) {
      // let res;
      // this.progresStatus = "check xendit id in graylite";
      try {
        const response = await axios.get(
          "invoices/checkinvoiceid/" + invoice_id
        );
        // console.log("result : " + JSON.stringify(response.data.data));
        console.log(
          "result xendit_id: " + JSON.stringify(response.data.data[0].xendit_id)
        );
        // return "response.data.data";
        if (response.data.success) {
          this.xenditId = response.data.data[0].xendit_id;
          if (this.xenditId != null) {
            this.timerOn();
            this.progresStatus = "xendit id ditemukan";
          } else {
            this.progresStatus = "xendit id tidak ditemukan";
            this.statusFormat(0);
            // if (response.data.data[0].payment_id != 5) {
            //   this.paymentNotByXendit();
            // } else {
            this.buttonText = "Cari Ulang";
            this.inProgress = false;
            this.progresStatus = "";
            this.statusFormat(0);
            clearInterval(this.intervalId);
            // this.xenditIdNotFound();
            //   this.progresStatus = "Finish";
            //   this.buttonText = "Cari Ulang";
            //   this.inProgress = !this.inProgress;
            //   clearInterval(this.intervalId);
            // }
          }
        }
      } catch (error) {
        // this.buttonText = "Cari Ulang";
        // this.inProgress = false;
        // this.progresStatus = "";
        // this.statusFormat(0);
        clearInterval(this.intervalId);

        this.xenditIdNotFound();
        console.error(error);
      }
    },

    xenditIdNotFound() {
      let result = confirm(
        "<i>Xendit id tidak ditemukan !, silahkan cek kembali metode pembayaranya</i>",
        "Konfirmasi"
      );
      result.then((dialogResult) => {
        if (dialogResult) {
          // this.buttonText = "Cari Ulang";
          // this.inProgress = false;
          // this.progresStatus = "";
          // this.statusFormat(0);
          // clearInterval(this.intervalId);
          this.seconds = 1;
          this.timerOn();
        } else {
          // this.buttonText = "Cari Ulang";
          // this.inProgress = false;
          // this.progresStatus = "";
          // this.statusFormat(0);
          // clearInterval(this.intervalId);
          this.seconds = 1;
          this.timerOn();
        }
      });
    },

    paymentNotByXendit() {
      let result = confirm(
        "<i>This Payment Not By Xendit ?</i>",
        "Konfirmasi Xendit id"
      );
      result.then((dialogResult) => {
        if (dialogResult) {
          this.buttonText = "Cari Ulang";
          this.inProgress = !this.inProgress;
          clearInterval(this.intervalId);
        }
      });
    },
    // async registerInvoiceIdToXendit(param) {
    //   // let res;
    //   this.progresStatus = "check xendit id in graylite";
    //   try {
    //     const response = await axios.get("invoices/reginvoiceid/" + invoice_id);
    //     console.log(
    //       "result xendit_id: " + JSON.stringify(response.data.data[0].xendit_id)
    //     );
    //     this.xenditId = JSON.stringify(response.data.data[0].xendit_id);
    //     if (this.xenditId != null) {
    //       this.progresStatus = "xendit id ditemukan";
    //     } else {
    //       this.progresStatus = "xendit id tidak ditemukan";
    //     }
    //   } catch (error) {
    //     console.error(error);
    //   }
    // },

    async getPaymentStatusByXenditId(xendit_id) {
      let res;
      this.progresStatus = "check payment status in xendit";
      try {
        const response = await axios.get(
          "invoices/getpaymentstatus/" + xendit_id
        );
        this.getXenditStatus = response.data.data;
        res = response.data.data;
        // console.log("res: " + JSON.stringify(res));
        console.log("PaymentStatus: " + res["status"]);

        this.xenditPaymentStatus = res["status"];
        if (this.xenditPaymentStatus != null) {
          this.timerOn();
          this.progresStatus = "Payment Status : " + this.xenditPaymentStatus;
          // } else if (this.xenditPaymentStatus == "UNPAID") {
          //   this.timerOn();
          //   this.progresStatus =
          //     "Status Pembayaran : " + this.xenditPaymentStatus;
          // } else if (this.xenditPaymentStatus == "PAID") {
          //   this.timerOn();
          //   this.progresStatus =
          //     "Status Pembayaran : " + this.xenditPaymentStatus;
          // } else if (this.xenditPaymentStatus == "SETTLED") {
          //   this.timerOn();
          //   this.progresStatus =
          //     "Status Pembayaran : " + this.xenditPaymentStatus;
          this.popupCheckDescription = true;
        } else {
          this.progresStatus = "Payment Status tidak ditemukan";
        }
      } catch (error) {
        console.error(error);
      }
    },

    onButtonClick() {
      if (this.inProgress) {
        this.buttonText = "Continue prosess";
        clearInterval(this.intervalId);
      } else {
        this.buttonText = "Stop prosess";

        if (this.seconds === 0) {
          this.seconds = maxValue;
        }
        //interval waktu setiap 1 detik
        this.intervalId = setInterval(() => this.timer(), 1000);
        console.log("intervalId: " + this.intervalId);
      }

      this.inProgress = !this.inProgress;
    },
    timerOn() {
      // this.seconds = maxValue;
      this.intervalId = setInterval(() => this.timer(), 1000);
    },
    timer() {
      this.seconds = this.seconds - 1;
      // console.log("seconds: " + this.seconds);
      if (this.seconds == 9) {
        this.progresStatus = "check xendit id in graylite";
      }
      if (this.seconds == 7) {
        this.getXenditIdByInvoiceId(this.invoiceId);
        clearInterval(this.intervalId);
        // if (this.xenditId != null) {
        //   this.progresStatus = "check xendit id in graylite";
        // let result = confirm(
        //   "<i>Pembayaran nomor Invoice ini tidak melalui XENDIT</i>",
        //   "Konfirmasi"
        // );
        // result.then((dialogResult) => {
        //   alert(dialogResult ? "Confirmed" : "Canceled");
        // });
      }

      if (this.seconds == 5) {
        if (this.xenditId) {
          this.getPaymentStatusByXenditId(this.xenditId);
          clearInterval(this.intervalId);
        }
      }
      if (this.seconds == 0) {
        this.progresStatus = "Finish";
        this.buttonText = "Cari Ulang";
        this.inProgress = !this.inProgress;
        clearInterval(this.intervalId);
        // this.popupCheckDescription = true;
      }
    },
  },
};
</script>
<style lang="scss">
@import "../themes/generated/variables.base.scss";
.app {
  background-color: #818381;
  // background: url("~@/assets/images/mitsubishi.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
}
.form {
  padding: 20% 0;
  text-align: center;
}

.login-header {
  text-align: center;
  margin-bottom: 10px;
  .title1 {
    color: $base-accent;
    font-weight: bold;
    font-size: 30px;
    margin: 0;
  }
}
.desc {
  margin-top: 30px;
  color: $base-accent;
  font-weight: bold;
  font-size: 10px;
  color: red;
}
.version {
  color: gray;
  font-weight: bold;
  font-size: 12px;
  margin: 0;
}
.login-notif {
  text-align: center;
  margin-bottom: 40px;
  color: red;
  font-weight: bold;
  font-size: 14px;
}
#progress-button {
  margin-bottom: 20px;
}
#progress-text {
  margin-bottom: 20px;
  text-align: center;
}
#progress-bar-status {
  display: inline-block;
}

.complete .dx-progressbar-range {
  background-color: green;
  margin-bottom: 10px;
}
</style>